<template>
  <form
    @submit.prevent="submitForm"
    @keydown="form.onKeydown($event)"
    name="checklist-todo"
    class="flex flex-col space-y-4"
    autocomplete="off"
  >
    <t-input-group>
      <template v-slot:label>
        {{ $t("checklist.form.description") }}
      </template>
      <t-input
        ref="descrInput"
        v-model="form.description"
        max="250"
        required
        class="text-black"
        :variant="{ danger: form.errors.has('description') }"
        :placeholder="$t('checklist.form.placeholder--description')"
      />
    </t-input-group>

    <t-input-group v-if="!todo">
      <label class="flex flex-row items-center justify-start w-auto px-2 py-1 my-2 space-x-2">
        <div class="flex items-center flex-shrink-0 pr-2 text-left">
          <t-checkbox
            v-model="form.personal"
            :variant="{ danger: form.errors.has('personal') }"
            :placeholder="$t('checklist.form.placeholder--personal')"
          />
        </div>
        <div class="text-sm">{{ $t("checklist.form.add-to-personal") }}</div>
      </label>
    </t-input-group>

    <t-button type="submit" :disabled="form.busy">{{
      form.busy ? $t("action.saving") : todo ? $t("checklist.btn--update-todo") : $t("checklist.btn--save-todo")
    }}</t-button>

    <template v-if="todo && todo.uuid">
      <t-button type="button" :disabled="!todo || form_loading || delete_loading" variant="link" class="text-red-500" @click="deleteTodo">{{
        delete_loading ? $t("action.deleting") : $t("action.delete")
      }}</t-button>
    </template>
  </form>
</template>

<script>
import Repository from "repositories/RepositoryFactory";
const EventRepository = Repository.get("my-events");

import { Form } from "vform";

export default {
  name: "ChecklistEventForm",
  props: {
    event: {
      type: Object,
      required: true,
      default: undefined,
    },
    todo: {
      type: Object,
      required: false,
      default: undefined,
    },
  },
  data: () => ({
    form: new Form({
      description: "",
      personal: false,
    }),
    form_loading: false,
    form_error: "",
    delete_loading: false,
  }),
  created() {
    this.form.fill(this.todo);
  },
  methods: {
    focusInput() {
      this.$refs.descrInput.focus();
    },
    deleteTodo() {
      if (!this.event || !this.todo) return;
      this.delete_loading = true;
      if (!confirm(this.$i18n.t("checklist.confirm--delete"))) {
        this.delete_loading = false;
        return;
      }
      EventRepository.deleteTodo(this.event.uuid, this.todo.uuid)
        .then(() => {
          this.$emit("checklist-updated");
          this.$emit("success");
          this.form.clear();
        })
        .catch((err) => {
          console.log("catched uncatched error", err.response);
          this.formErrors = [err.response.message || "Failed to delete event."];
        })
        .then(() => {
          this.delete_loading = false;
        });
    },
    submitForm() {
      this.form_loading = true;
      if (this.todo) {
        this.update(this.todo.uuid);
      } else {
        this.create();
      }
    },
    create() {
      const { description, personal } = this.form;
      EventRepository.addTodo(this.event.uuid, description, personal)
        .then((response) => {
          this.$emit("checklist-updated", response.data.todo);
          this.$emit("success");
          this.form.reset();
          this.form.clear();
        })
        .catch((err) => {
          console.log("catched uncatched error", err.response);
          if (err.response && err.response.status) {
            switch (err.response.status) {
              case 422:
                this.form.errors.set(err.response.data.errors);
                this.form_error = err.response.data.message;
                return;
            }
          }
          this.formErrors = [this.$i18n.t("unknown_error")];
        })
        .then(() => {
          this.form_loading = false;
        });
    },
    update(uuid) {
      const { description } = this.form;
      EventRepository.updateTodo(this.event.uuid, uuid, description)
        .then((response) => {
          this.$emit("checklist-updated", response.data.todo);
          this.$emit("success");
          this.form.reset();
          this.form.clear();
        })
        .catch((err) => {
          console.log("catched uncatched error", err.response);
          if (err.response && err.response.status) {
            switch (err.response.status) {
              case 422:
                this.form.errors.set(err.response.data.errors);
                this.form_error = err.response.data.message;
                return;
            }
          }
          this.formErrors = [this.$i18n.t("unknown_error")];
        })
        .then(() => {
          this.form_loading = false;
        });
    },
  },
};
</script>

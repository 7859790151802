<template>
  <div>
    <t-select :options="options" v-model="activeComponent" class="mb-4 text-black"></t-select>

    <keep-alive>
      <component
        :is="activeComponent"
        :items="items"
        :event-uuid="event.uuid"
        @bulkCreateTodosSuccess="handleSuccess"
        :event="event"
        @success="handleSuccess"
      ></component>
    </keep-alive>
  </div>
</template>

<script>
import ChecklistEventForm from "components/Checklist/EventForm";
import PersonalChecklistItems from "components/Event/PersonalChecklistItems";

export default {
  props: {
    event: {
      type: Object,
    },
    items: {
      type: Array,
    },
  },

  components: {
    ChecklistEventForm,
    PersonalChecklistItems,
  },

  data() {
    return {
      activeComponent: "checklist-event-form",
      options: [
	
        { value: "personal-checklist-items", text: this.$t("info.title--checklist") },
       	{ value: "checklist-event-form", text: this.$t("event.btn--add-todo") },
      ],
    };
  },

  methods: {
    handleSuccess() {
      this.$emit("success");
    },
  },
};
</script>

<style></style>

<template>
  <div class="pb-32">
    <t-button class="mb-4" @click="onAddAllItems">{{
      $t("action.select_all_items")
    }}</t-button>

    <p class="mb-2">{{ $t("checklist.intro_popup") }}</p>

    <div
      class="mb-2 rounded-lg p-4 shadow-lg transition"
      :class="{
        'bg-white text-brand-dark': !itemInArray(item.description),
        'bg-brand-navblue text-white': itemInArray(item.description),
      }"
      v-for="item in items"
      :key="item.uuid"
      @click="onAddItem(item.description)"
    >
      {{ item.description }}
    </div>

    <t-button
      class="mt-8"
      :disabled="!chosenItems.length"
      @click="addItemsToChecklist"
      >{{ $t("checklist.add") }}</t-button
    >
  </div>
</template>

<script>
import MyEventRepository from "../../repositories/MyEventRepository";

export default {
  name: "PersonalChecklistItems",

  props: {
    eventUuid: {
      type: String,
    },

    items: {
      type: Array,
    },
  },

  data() {
    return {
      chosenItems: [],
    };
  },

  methods: {
    onAddAllItems() {
      this.chosenItems = this.items.map((item) => item.description);
    },

    async addItemsToChecklist() {
      try {
        const { data } = await MyEventRepository.bulkAddTodos(
          this.eventUuid,
          this.chosenItems
        );

        if (data.success) {
          this.$emit("bulkCreateTodosSuccess");
        }

        this.chosenItems = [];
      } catch (e) {
        console.log(e);
      }
    },

    onAddItem(item) {
      if (this.itemInArray(item)) {
        this.chosenItems = this.chosenItems.filter(
          (chosenItem) => chosenItem !== item
        );
        return;
      }

      this.chosenItems.push(item);
    },

    itemInArray(description) {
      return this.chosenItems.includes(description);
    },
  },
};
</script>

<style scoped></style>

<template>
  <div class="w-full mx-auto event-checklist">
    <portal to="topbar-left" v-if="event">
      <router-link
        :to="{ name: 'my-event', params: { uuid: event.uuid } }"
        class="relative font-bold"
        :class="{
          'text-white': isCheckedIn,
          'text-brand-dark': !isCheckedIn,
        }"
        key="event-checklist-back"
      >
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12 22" class="w-6 h-6">
          <g fill="currentColor" fill-rule="evenodd" transform="translate(-4 -1)">
            <path d="M7.621 12l7.94-7.94a1.5 1.5 0 00-2.122-2.12l-9 9a1.5 1.5 0 000 2.12l9 9a1.5 1.5 0 002.122-2.12L7.62 12z" />
          </g>
        </svg>
      </router-link>
    </portal>

    <div class="flex flex-col w-full max-w-screen-sm min-h-full p-4 mx-auto space-y-4" v-if="event">
      <div class="flex flex-row justify-between">
        <div class="flex flex-col items-start space-y-1">
          <div class="flex items-center space-x-2">
            <h2 class="text-xl font-bold">{{ $t("event.checklist") }}</h2>

            <div class="relative">
              <icon-info class="w-5 h-5 fill-current dark:text-white" @click="infoOpen = !infoOpen" />
              <transition name="fade">
                <div
                  class="absolute left-0 z-50 min-h-full p-2 mx-auto mt-2 text-xs leading-normal bg-white rounded-lg shadow top w-44 text-brand-dark"
                  v-if="infoOpen"
                >
                  <p>{{ $t("event.checklist_intro") }}</p>
                </div>
              </transition>
            </div>
          </div>

          <h3 class="font-semibold">{{ event.name }}</h3>
        </div>

        <div class="flex items-start justify-end flex-1">
          <div class="flex flex-row items-center justify-end flex-grow-0 flex-shrink-0 space-x-2">
            <t-button :variant="{ small: !isCheckedIn, smallwhite: isCheckedIn }" @click="addTodo">
              <icon-plus class="w-3 h-3" />
              <span class="ml-2 text-xs truncate">{{ $t("event.btn--add-todo") }}</span>
            </t-button>
          </div>
        </div>
      </div>

      <div v-if="checklist && checklist.length">
        <div class="text-sm text-right">
          <span>{{ todoCompletedCount }}/{{ checklist.length }} done</span>
        </div>

        <div class="relative w-full h-1 mb-2 overflow-hidden rounded-lg">
          <div class="absolute top-0 left-0 w-full h-full bg-gray-50"></div>

          <div
            class="absolute top-0 left-0 h-full transition-all bg-brand-darkblue"
            :style="`width: ${(todoCompletedCount / checklist.length) * 100}%`"
          ></div>
        </div>

        <transition-group name="list" tag="div">
          <div :key="`event_${event.uuid}_task_${task.uuid}`" class="relative flex items-center space-y-2" v-for="task in checklist">
            <div class="absolute flex items-center space-x-1 left-1">
              <div class="flex items-center justify-center w-12 h-12 mt-2 bg-red-200 rounded-lg">
                <icon-loading v-if="isDeleting" class="w-4 h-4 text-red-400 fill-current animate-spin" />
                <icon-trash v-else @click="deleteChecklistItem(task)" class="w-6 h-6 text-red-600 fill-current" />
              </div>

              <div class="flex items-center justify-center w-12 h-12 mt-2 bg-gray-100 bg-opacity-75 rounded-lg">
                <icon-star @click="favoriteChecklistItem(task)" class="w-6 h-6 text-gray-600 fill-current" />
              </div>
            </div>

            <checklist-item
              data-draggable="true"
              class="relative transition-all checklist__item"
              @touchmove.native="handleSwipe"
              @touchstart.native="handleSwipeStart"
              @touchend.native="handleSwipeEnd($event, 110)"
              :task="task"
              @editTodo="editTodo"
              @updateTaskFinished="updateTaskFinished"
            />
          </div>
        </transition-group>

        <transition name="slide-fade">
          <lottie v-if="hasCompletedAllTodos" :options="defaultOptions" :height="160" :width="120" />
        </transition>
      </div>

      <div v-else>
        <div class="relative flex items-center justify-center my-12">
          <icon-checklist class="relative z-20 w-20 h-20" />
          <blob class="absolute z-10 w-64 h-64 opacity-25" />
        </div>

        <div class="text-sm text-center">
          {{ $t("event.empty--checklist") }}
        </div>
      </div>
    </div>

    <popup-pane
      v-if="formOpen || checklistOpen"
      :title="$t(`event.title--${activeEventTodo ? 'edit' : 'add'}-todo`)"
      @close="closePopup"
      :dark="isCheckedIn"
    >
      <checklist-event-form v-if="!!activeEventTodo" @success="handleTodoSuccess" :todo="activeEventTodo" :event="event" />
      <checklist-event v-else @success="handleTodoSuccess" :event="event" :items="personal_checklist"></checklist-event>
    </popup-pane>
  </div>




</template>

<script>
import { mapGetters } from "vuex";
import Lottie from "vue-lottie";
import IconInfo from "assets/icons/info.svg";
import IconPlus from "assets/icons/plus.svg";
import IconChecklist from "assets/icons/checklist.svg";
import IconTrash from "assets/icons/trash-bin.svg";
import IconStar from "assets/icons/star.svg";
import Blob from "assets/icons/blob.svg";
import PopupPane from "components/PopupPane";
import Repository from "repositories/RepositoryFactory";
import ChecklistEventForm from "components/Checklist/EventForm";
import ChecklistEvent from "components/Checklist/Event";
import ChecklistItem from "components/Event/ChecklistItem";
import SwipeMixin from "@/mixins/Swipe";
import * as animationData from "@/assets/animations/animation.json";
import { createAlert } from "../../utils/constants";



const EventRepository = Repository.get("my-events");
const TodoRepository = Repository.get("todos");

export default {
  name: "event-single-checklist",

  mixins: [SwipeMixin],

  components: {
    ChecklistItem,
    ChecklistEvent,
    IconPlus,
    IconChecklist,
    IconInfo,
    IconTrash,
    IconStar,
    Blob,
    PopupPane,
    ChecklistEventForm,
    Lottie,
  },

  props: {
    event: {
      type: Object,
    },
  },

  watch: {
    event: {
      deep: true,
      handler() {
        this.hasCompletedAllTodos = this.event.todos.every((todo) => !!todo.finished_at);

        this.checkTodoCompletionCount();
      },
    },
  },        
  
  


  
  created() {
    this.hasCompletedAllTodos = this.event.todos.every((todo) => !!todo.finished_at);
    this.checkTodoCompletionCount();
    this.loadPersonalChecklist();
  },

  data: () => ({
    loading: true,
    isDeleting: false,
    formOpen: true, 
    activeEventTodo: undefined, 
    personal_checklist: [],
    hasCompletedAllTodos: false,
    defaultOptions: { animationData: animationData.default, loop: false },
    infoOpen: false,
    todoCompletedCount: 0,
    checklistOpen: false,
  }),



  methods: {
    closePopup() {
      this.formOpen = false;
      this.checklistOpen = false;
      this.activeEventTodo = null;
      let scriptEl = document.createElement('script');
    scriptEl.innerHTML = `  
      list = document.querySelectorAll('div.w-0');
      
      for (i = 0; i < list.length; i++) 
      {
        list[i].setAttribute("onclick", "this.previousSibling.children[0].checked = !this.previousSibling.children[0].checked;");
      }       `;
    document.querySelector('body').appendChild(scriptEl);
    },

    addTodo() {
      this.checklistOpen = true;
      this.activeEventTodo = null;
    },

    editTodo(todo) {
      this.formOpen = true;
      this.activeEventTodo = todo;
    },

    handleTodoSuccess() {
      this.formOpen = false;
      this.checklistOpen = false;
      this.$emit("event-updated");
    },

    updateTaskFinished({ state, task }) {
      if (state) {
        EventRepository.finishTodo(this.event.uuid, task.uuid).then(() => {
          this.$emit("event-updated");
        });
      } else {
        EventRepository.unfinishTodo(this.event.uuid, task.uuid).then(() => {
          this.$emit("event-updated");
        });
      }
    },

    loadPersonalChecklist() {
      this.loading = true;
      TodoRepository.all()
        .then((response) => {
          this.personal_checklist = response.data.todos;
        })
        .then(() => {
          this.loading = false;
        });
    },

    async deleteChecklistItem(item) {
      this.isDeleting = true;

      try {
        await EventRepository.deleteTodo(this.event.uuid, item.uuid);
        this.$emit("event-updated");
      } catch (e) {
        console.log(e);
      }

      this.isDeleting = false;
    },

    async toggleFavorite(item) {
      await EventRepository.toggleFavoriteTodo(this.event.uuid, item.uuid);
      this.$emit("event-updated");
    },

    async favoriteChecklistItem(item) {
      try {
        await this.toggleFavorite(item);
        const todoToDelete = this.personal_checklist.find((checklistItem) => checklistItem.description === item.description);

        if (todoToDelete) {
          await TodoRepository.delete(todoToDelete.uuid);
          this.$store.commit("alerts/ADD_ALERT", createAlert("Deleted item from personal checklist", "SUCCESS"));
          this.loadPersonalChecklist();
          return;
        }

        await TodoRepository.create({ description: item.description });
        this.$store.commit("alerts/ADD_ALERT", createAlert("Added item to personal checklist", "SUCCESS"));
        this.loadPersonalChecklist();
      } catch (e) {
        console.log(e);
      }
    },

    checkTodoCompletionCount() {
      if (!this.event.todos) {
        this.todoCompletedCount = 0;
        return;
      }

      this.todoCompletedCount = this.event.todos.filter((todo) => todo.finished_at !== null).length;
    },
  },

  computed: {
    ...mapGetters(["isCheckedIn"]),
    checklist() {
      return this.event?.todos || [];
    },
  },

  metaInfo() {
    return {
      title: `${this.event ? this.event.name : "Loading..."} - ${this.$i18n.t("event.checklist")}`,
    };
  },
};
</script>

